//For resource View -- Customized for Seedcode
function AgendaResourceView(element, calendar, viewName) {
	var t = this;

	// exports
	t.renderAgenda = renderAgenda;
	t.setWidth = setWidth;
	t.setHeight = setHeight;
	t.afterRender = afterRender;
	t.computeDateTop = computeDateTop;
	t.getIsCellAllDay = getIsCellAllDay;
	t.allDayRow = function () {
		return allDayRow;
	}; // badly named
	t.getCoordinateGrid = function () {
		return coordinateGrid;
	}; // specifically for AgendaEventRenderer
	t.getHoverListener = function () {
		return hoverListener;
	};
	t.colLeft = colLeft;
	t.colRight = colRight;
	t.colContentLeft = colContentLeft;
	t.colContentRight = colContentRight;
	t.getDaySegmentContainer = function () {
		return daySegmentContainer;
	};
	t.getSlotSegmentContainer = function () {
		return slotSegmentContainer;
	};
	t.getSlotContainer = function () {
		return slotContainer;
	};
	t.getRowCnt = function () {
		return 1;
	};
	t.getColCnt = function () {
		return colCnt;
	};
	t.getColWidth = function () {
		return colWidth;
	};
	t.getSnapHeight = function () {
		return snapHeight;
	};
	t.getSnapDuration = function () {
		return snapDuration;
	};
	t.getSlotHeight = function () {
		return slotHeight;
	};
	t.getSlotDuration = function () {
		return slotDuration;
	};
	t.getMinTime = function () {
		return minTime;
	};
	t.getMaxTime = function () {
		return maxTime;
	};
	t.defaultSelectionEnd = defaultSelectionEnd;
	t.renderDayOverlay = renderDayOverlay;
	t.renderSelection = renderSelection;
	t.clearSelection = clearSelection;
	t.reportDayClick = reportDayClick; // selection mousedown hack
	t.dragStart = dragStart;
	t.dragStop = dragStop;
	t.getResources = calendar.fetchResources;

	// imports
	View.call(t, element, calendar, viewName);
	OverlayManager.call(t);
	SelectionManager.call(t);
	ResourceEventRenderer.call(t);
	var opt = t.opt;
	var trigger = t.trigger;
	var renderOverlay = t.renderOverlay;
	var clearOverlays = t.clearOverlays;
	var reportSelection = t.reportSelection;
	var unselect = t.unselect;
	var daySelectionMousedown = t.daySelectionMousedown;
	var slotSegHtml = t.slotSegHtml;
	var cellToDate = t.cellToDate;
	var dateToCell = t.dateToCell;
	var createExternalDragTimeDates = t.createExternalDragTimeDates;
	var rangeToSegments = t.rangeToSegments;
	var formatDate = calendar.formatDate;
	var calculateWeekNumber = calendar.calculateWeekNumber;

	// locals

	var dayTable;
	var dayHead;
	var dayHeadCells;
	var dayBody;
	var dayBodyCells;
	var dayBodyCellInners;
	var dayBodyCellContentInners;
	var dayBodyFirstCell;
	var dayBodyFirstCellStretcher;
	var bodyPosition;
	var slotLayer;
	var daySegmentContainer;
	var allDayTable;
	var allDayRow;
	var slotScroller;
	var slotContainer;
	var slotSegmentContainer;
	var slotTable;
	var selectionHelper;

	var viewWidth;
	var viewHeight;
	var axisWidth;
	var colWidth;
	var gutterWidth;

	var slotDuration;
	var slotHeight; // TODO: what if slotHeight changes? (see issue 650)

	var snapDuration;
	var snapRatio; // ratio of number of "selection" slots to normal slots. (ex: 1, 2, 4)
	var snapHeight; // holds the pixel hight of a "selection" slot

	var colCnt;
	var slotCnt;
	var coordinateGrid;
	var hoverListener;
	var colPositions;
	var colContentPositions;
	var slotTopCache = {};

	var tm;
	var rtl;
	var minTime;
	var maxTime;
	var colFormat;

	var manageClone = t.manageClone;

	//Customized for seedcode
	var dateHead;
	var dateHeadCells;
	var resources;
	var moreEventsContainer;
	var moreEvents;
	var resourceDays = opt('resourceDays');
	var resourceRangeToSegments = t.resourceRangeToSegments;

	var preventScrollReset;

	var useShortNames;

	/* Rendering
	-----------------------------------------------------------------------------*/

	element.addClass('fc-agenda');
	//disableTextSelection(element.addClass('fc-agenda'));

	function renderAgenda() {
		resources = t.getResources(false);
		colCnt = resources.length;
		updateOptions();

		if (!dayTable) {
			// first time rendering?
			preventScrollReset = false;
			buildSkeleton(); // builds day table, slot area, events containers
		} else {
			preventScrollReset = true;
			buildDayTable(); // rebuilds day table
		}

		//Enable tooltips for this view //Customized for SeedCode
		calendar.generateBreakoutTooltips(
			'bottom',
			$(element),
			resources,
			useShortNames
		);
	}

	function updateOptions() {
		tm = opt('theme') ? 'ui' : 'fc';
		rtl = opt('isRTL');

		colFormat = getFormatFromMatrix(
			opt('columnFormat'),
			(calendar.getContentWidth() - 75) / resourceDays
		);

		minTime = moment.duration(opt('minTime'));
		maxTime = moment.duration(opt('maxTime'));

		slotDuration = moment.duration(opt('slotDuration'));
		snapDuration = opt('snapDuration');
		snapDuration = snapDuration
			? moment.duration(snapDuration)
			: slotDuration;
	}

	/* Build DOM
	-----------------------------------------------------------------------*/

	function buildSkeleton() {
		//Customized for SeedCode. Currently just to display minutes between hours.
		var s;
		var headerClass = tm + '-widget-header';
		var contentClass = tm + '-widget-content dbk_cellContent';
		var slotTime;
		var slotDate;
		var minutes;
		var slotNormal =
			slotDuration.asMinutes() % slotDuration.asMinutes() === 0;

		// moreEventsContainer = buildMoreEventHTML() //customized for seedcode

		buildDayTable();

		slotLayer = $(
			"<div style='position:absolute;z-index:2;left:0;width:100%'/>"
		).appendTo(element);

		if (opt('allDaySlot')) {
			daySegmentContainer = $(
				"<div class='fc-event-container' style='position:absolute;z-index:8;top:0;left:0'/>"
			).appendTo(slotLayer);

			s =
				"<table style='width:100%' class='fc-agenda-allday' cellspacing='0'>" +
				'<tr>' +
				"<th class='" +
				headerClass +
				" fc-agenda-axis'>" +
				(opt('allDayHTML') || htmlEscape(opt('allDayText'))) +
				'</th>' +
				'<td>' +
				"<div id='fc-allday-content' class='fc-day-content'><div style='position:relative'/>" +
				moreEventsContainer +
				'</div>' + //Customized for seedcode
				//"<div id='fc-allday-content' class='fc-day-content'><div style='position:relative'/></div>" + //Customized for seedcode

				'</td>' +
				"<th class='" +
				headerClass +
				" fc-agenda-gutter'>&nbsp;</th>" +
				'</tr>' +
				'</table>';

			allDayTable = $(s).appendTo(slotLayer);

			allDayRow = allDayTable.find('tr');
			dayBind(allDayRow.find('td'));

			//Customized for seedcode
			moreEvents = allDayTable.find('.more-events');
			moreEventsBind(moreEvents);

			slotLayer.append(
				"<div class='fc-agenda-divider " +
					headerClass +
					"'>" +
					"<div class='fc-agenda-divider-inner dbk_allDay_divider'/>" +
					'</div>'
			);
		} else {
			daySegmentContainer = $([]); // in jQuery 1.4, we can just do $()
		}
		//Customized for SeedCode: Added a class to the div so we can target it later. IE9 doesn't like position absolute as it leaves a gap on the right side.
		slotScroller = $(
			"<div class='fc-slot-scroll calendar-scroll touch-scroll'/>"
		).appendTo(slotLayer);

		slotContainer = $(
			"<div class='fc-slot-scroll-content' style='position:relative;width:100%;overflow:hidden'/>"
		).appendTo(slotScroller);

		slotSegmentContainer = $(
			"<div class='fc-event-container' style='position:absolute;z-index:8;top:0;left:0'/>"
		).appendTo(slotContainer);

		s =
			"<table class='fc-agenda-slots' style='width:100%' cellspacing='0'>" +
			'<tbody>';

		slotTime = moment.duration(+minTime); // i wish there was .clone() for durations
		slotCnt = 0;
		while (slotTime < maxTime) {
			slotDate = calendar.unzoneDate(t.start).time(slotTime); // will be in UTC but that's good. to avoid DST issues
			minutes = slotDate.minutes();
			s +=
				"<tr class='fc-slot" +
				slotCnt +
				' ' +
				(!minutes ? '' : 'fc-minor') +
				"'>" +
				"<th class='fc-agenda-axis time " +
				headerClass +
				"'>" +
				(!slotNormal || !minutes
					? htmlEscape(formatDate(slotDate, opt('axisFormat')))
					: '<span class="minutes">' + minutes + '</span>') + //This is customized to show minuts. Was "&nbsp;"
				'</th>' +
				"<td class='" +
				contentClass +
				"'>" +
				"<div style='position:relative'>&nbsp;</div>" +
				'</td>' +
				'</tr>';
			slotTime.add(slotDuration);
			slotCnt++;
		}

		s += '</tbody>' + '</table>';

		slotTable = $(s).appendTo(slotContainer);

		slotBind(slotTable.find('td'));

		timeline =
			"<div id='timelineContainer'>" +
			"<span id='timelineTime'></span>" +
			'</div>';

		$('.fc-agenda-slots').append(timeline);
	}

	/* Build Day Table
	-----------------------------------------------------------------------*/

	function buildDayTable() {
		var html = buildDayTableHTML();
		var buildMoreEvents = buildMoreEventHTML();
		var allDayContent = $('#fc-allday-content > div');

		if (dayTable) {
			dayTable.remove();
			$('.more-events-container').remove(); //Customized for seedcode
		}

		dayTable = $(html).appendTo(element);

		if (allDayContent.length) {
			moreEventsContainer = $(buildMoreEvents).appendTo(allDayContent);
			//Bind click events
			moreEvents = moreEventsContainer.find('.more-events');
			moreEventsBind(moreEvents);
		} else {
			moreEventsContainer = buildMoreEvents;
		}

		dateHead = dayTable.find('thead.date-column-header');
		dayHead = dayTable.find('thead.resource-column-header');
		dateHeadCells = dateHead.find('th').slice(1, -1); // exclude gutter
		dayHeadCells = dayHead.find('th').slice(1, -1); // exclude gutter
		dayBody = dayTable.find('tbody');
		dayBodyCells = dayBody.find('td').slice(0, -1); // exclude gutter
		dayBodyCellInners = dayBodyCells.find('> div');
		dayBodyCellContentInners = dayBodyCells.find('.fc-day-content > div');

		dayBodyFirstCell = dayBodyCells.eq(0);
		dayBodyFirstCellStretcher = dayBodyCellInners.eq(0);

		markFirstLast(dayHead.add(dayHead.find('tr')));
		markFirstLast(dayBody.add(dayBody.find('tr')));

		// TODO: now that we rebuild the cells every time, we should call dayRender
	}

	function buildDayTableHTML() {
		var html =
			buildDateHeadHTML() +
			"<table style='width:100%' class='fc-agenda-days fc-border-separate' cellspacing='0'>" +
			buildDayTableHeadHTML() +
			buildDayTableBodyHTML() +
			'</table>';

		return html;
	}

	function buildDateHeadHTML() {
		var headerClass = tm + '-date-header';
		var date;
		var today = calendar.getNow().stripTime();
		var html = '';
		var weekText;
		var col;
		var todayClass;

		if (resourceDays == 1) {
			return html;
		}

		html +=
			"<table style='width:100%' class='' cellspacing='0'>" +
			"<thead class='date-column-header' style='border: none;'>" +
			'<tr>';

		// if (opt('weekNumbers')) {
		// 	date = cellToDate(0, 0);
		// 	weekText = calculateWeekNumber(date);
		// 	if (rtl) {
		// 		weekText += opt('weekNumberTitle');
		// 	}
		// 	else {
		// 		weekText = opt('weekNumberTitle') + weekText;
		// 	}
		// 	html +=
		// 		"<th class='fc-agenda-axis fc-week-number " + headerClass + "'>" +
		// 		htmlEscape(weekText) +
		// 		"</th>";
		// }
		// else {
		html += "<th class='fc-agenda-axis " + headerClass + "'>&nbsp;</th>";
		// }
		for (var days = 0; days < resourceDays; days++) {
			// for (col=0; col<colCnt; col++) {
			date = cellToDate(0, days); //Modified to show just focus date as each column isn't a different date anymore
			todayClass = '';
			if (date.isSame(today, 'day')) {
				todayClass = 'fc-today';
			}
			html +=
				"<th class='" +
				todayClass +
				' fc-' +
				dayIDs[date.day()] +
				' ' +
				'dbk_day_' +
				dayIDs[date.day()] +
				' fc-col' +
				days +
				' ' +
				headerClass +
				"'>" +
				'<span>' +
				htmlEscape(date.format(colFormat));
			'</span>' + '</th>';
			// }
		}

		html +=
			"<th class='fc-agenda-gutter " +
			headerClass +
			"'>&nbsp;</th>" +
			'</tr>' +
			'</thead>' +
			'</table>';

		return html;
	}

	function buildDayTableHeadHTML() {
		var headerClass = tm + '-widget-header';
		var date;
		var html = '';
		var weekText;
		var col;
		var contentWidth = calendar.getContentWidth();
		var shortNameThreshold = 100;
		useShortNames =
			contentWidth / (colCnt * resourceDays) < shortNameThreshold;
		var resourceNameSource = useShortNames ? 'shortName' : 'name';
		var resourceName;
		var resourceClass;

		html +=
			"<thead class='resource-column-header dbk_columnHeader'>" + '<tr>';

		if (opt('weekNumbers')) {
			date = cellToDate(0, 0);
			weekText = calculateWeekNumber(date, resourceDays);
			if (rtl) {
				weekText += opt('weekNumberTitle');
			} else {
				weekText = opt('weekNumberTitle') + weekText;
			}
			html +=
				"<th class='fc-agenda-axis fc-week-number " +
				headerClass +
				"'>" +
				htmlEscape(weekText) +
				'</th>';
		} else {
			html +=
				"<th class='fc-agenda-axis " + headerClass + "'>&nbsp;</th>";
		}
		for (var days = 0; days < resourceDays; days++) {
			for (col = 0; col < colCnt; col++) {
				resourceName =
					resources[col][resourceNameSource] || resources[col].name;
				date = cellToDate(0, days); //Modified to show just focus date as each column isn't a different date anymore

				resourceClass = resources[col].class
					? ' ' + resources[col].class
					: '';

				html +=
					"<th class='fc-" +
					dayIDs[date.day()] +
					' ' +
					'dbk_day_' +
					dayIDs[date.day()] +
					' fc-col' +
					col +
					' ' +
					headerClass +
					resourceClass +
					"'>" +
					// "<span data-toggle='tooltip' title='" + htmlEscape(resources[col].name) + "'>" +
					"<span data-toggle='tooltip' data-position='" +
					col +
					"'>" +
					htmlEscape(resourceName);
				'</span>' + '</th>';
			}
		}

		html +=
			"<th class='fc-agenda-gutter " +
			headerClass +
			"'>&nbsp;</th>" +
			'</tr>' +
			'</thead>';

		return html;
	}

	function buildDayTableBodyHTML() {
		var headerClass = tm + '-widget-header'; // TODO: make these when updateOptions() called
		var contentClass = tm + '-widget-content dbk_cellContent';
		var date;
		var today = calendar.getNow().stripTime();
		var col;
		var cellsHTML;
		var cellHTML;
		var classNames;
		var resource;
		var html = '';

		html +=
			"<tbody class='dbk_calendarContent'>" +
			'<tr>' +
			"<th class='fc-agenda-axis " +
			headerClass +
			"'>&nbsp;</th>";

		cellsHTML = '';
		for (var days = 0; days < resourceDays; days++) {
			for (col = 0; col < colCnt; col++) {
				resource = resources[col];

				date = cellToDate(0, days); //Modified to get date from first column although we could probably just pass in a focus date instead.

				classNames = [
					'fc-col' + col,
					'fc-' + dayIDs[date.day()],
					'dbk_day_' + dayIDs[date.day()],
					contentClass,
				];
				//Test if we are are in the last column of a multi day resource view
				if (col === colCnt - 1 && days < resourceDays - 1) {
					classNames.push('fc-date-seperator');
				}
				if (date.isSame(today, 'day')) {
					classNames.push(
						tm + '-state-highlight',
						'fc-today',
						'dbk_day_today'
					);
				}
				// else if (date < today) {
				// 	classNames.push('fc-past');
				// }
				//Maybe find a beter class name than fc-future as these dates could be past or future
				else {
					classNames.push('fc-future');
				}
				//Top row for all day events
				cellHTML =
					"<td class='" +
					classNames.join(' ') +
					"'" +
					" data-date='" +
					date.format() +
					"'" +
					" data-resource='" +
					resource.nameSafe +
					"'" +
					'>' +
					'<div>' +
					"<div class='fc-day-content'>" +
					"<div style='position:relative'>&nbsp;</div>" +
					'</div>' +
					'</div>' +
					'</td>';

				cellsHTML += cellHTML;
			}
		}
		html += cellsHTML;
		//Add Gutter to end of grid
		html +=
			"<td class='fc-agenda-gutter " +
			contentClass +
			"'>&nbsp;</td>" +
			'</tr>' +
			'</tbody>';

		return html;
	}

	function buildMoreEventHTML() {
		var col;
		var cellsHTML;
		var cellHTML;
		var html = '';

		html += '<tbody>' + '<tr>';

		cellsHTML = '';
		for (var days = 0; days < resourceDays; days++) {
			for (col = 0; col < colCnt; col++) {
				//Top row for all day events
				cellHTML =
					'<td>' +
					"<div id='fc-cell-" +
					'0' +
					'-' +
					(col + days * colCnt) +
					"'>" +
					"<span class='more-events'></span>" +
					'</div>' +
					'</td>';

				cellsHTML += cellHTML;
			}
		}

		html += cellsHTML;
		//Add Gutter to end of grid
		html += '</tr>' + '</tbody>';

		return (
			"<table class='more-events-container' style='width: 100%; table-layout: fixed; border-collapse: collapse; border: none;' border='0' cellpadding='0' cellspacing='0'>" +
			html +
			'</table>'
		);
	}

	// TODO: data-date on the cells

	/* Dimensions
	-----------------------------------------------------------------------*/

	function setHeight(height) {
		if (height === undefined) {
			height = viewHeight;
		}
		viewHeight = height;
		slotTopCache = {};

		var elementPosition = element[0].getBoundingClientRect();
		var headHeight =
			dayBody[0].getBoundingClientRect().top - elementPosition.top;
		var allDayHeight = slotScroller.position().top; // including divider
		var bodyHeight = Math.min(
			// total body height, including borders
			height - headHeight, // when scrollbars
			slotTable.height() + allDayHeight + 1 // when no scrollbars. +1 for bottom border
		);

		dayBodyFirstCellStretcher.height(bodyHeight - vsides(dayBodyFirstCell));
		slotLayer.css('top', headHeight);

		slotScroller.height(bodyHeight - allDayHeight - 1);

		// the stylesheet guarantees that the first row has no border.
		// this allows .height() to work well cross-browser.
		var slotHeight0 = slotTable.find('tr:first').height() + 1; // +1 for bottom border
		var slotHeight1 = slotTable.find('tr:eq(1)').height();
		// HACK: i forget why we do this, but i think a cross-browser issue
		slotHeight = (slotHeight0 + slotHeight1) / 2;

		snapRatio = slotDuration / snapDuration;
		snapHeight = slotHeight / snapRatio;
	}

	function setWidth(width) {
		viewWidth = width;
		colPositions.clear();
		colContentPositions.clear();

		var axisFirstCells = dayHead.find('th:first');
		axisFirstCells = axisFirstCells.add(dayHead.find('th:first'));

		if (allDayTable) {
			axisFirstCells = axisFirstCells.add(allDayTable.find('th:first'));
		}
		axisFirstCells = axisFirstCells.add(slotTable.find('th:first'));

		axisWidth = 0;
		setOuterWidth(
			axisFirstCells.width('').each(function (i, _cell) {
				axisWidth = Math.max(axisWidth, $(_cell).outerWidth());
			}),
			axisWidth
		);

		var gutterCells = dayTable.find('.fc-agenda-gutter');
		if (allDayTable) {
			gutterCells = gutterCells.add(
				allDayTable.find('th.fc-agenda-gutter')
			);
		}

		var slotTableWidth = slotScroller[0].clientWidth; // needs to be done after axisWidth (for IE7)

		gutterWidth = slotScroller.width() - slotTableWidth;

		//Add gutter width back to slot table width since we don't want to show a gutter
		slotTableWidth += gutterWidth;

		//Reset gutter width to 0 so anything that offsets won't change
		gutterWidth = 0;

		if (gutterWidth || false) {
			//Disabled gutter width for now// Customized for SeedCode
			setOuterWidth(gutterCells, gutterWidth);
			gutterCells.show().prev().removeClass('fc-last');
		} else {
			gutterCells.hide().prev().addClass('fc-last');
		}

		colWidth = Math.floor(
			(slotTableWidth - axisWidth) / (colCnt * resourceDays)
		);

		setOuterWidth(dayHeadCells.slice(0, -1), colWidth);

		//Customized for SeedCode. Apply column width to label in last column so a long label doesn't push content. Remove 2 at the end for a bit of breathing room.
		var dateColWidth = Math.floor(colWidth * colCnt);
		setOuterWidth(dateHeadCells.slice(0, -1), dateColWidth);

		var lastColWidth =
			slotTableWidth -
			colWidth * (dayHeadCells.length - 1) -
			axisWidth -
			2;
		setOuterWidth(dayHeadCells.slice(-1).find('span'), lastColWidth);

		var lastDateColWidth =
			slotTableWidth -
			dateColWidth * (dateHeadCells.length - 1) -
			axisWidth -
			2;
		setOuterWidth(dateHeadCells.slice(-1).find('span'), lastDateColWidth);

		var moreEvents = $('.more-events-container').find('td');
		setOuterWidth(moreEvents.slice(0, -1), colWidth);
		setOuterWidth(moreEvents.slice(-1).find('div'), lastColWidth);

		bodyPosition = dayBody[0].getBoundingClientRect();
	}

	/* Scrolling
	-----------------------------------------------------------------------*/

	function resetScroll() {
		var top = computeTimeTop(moment.duration(opt('scrollTime'))) + 1; // +1 for the border

		function scroll() {
			slotScroller.scrollTop(top);
		}

		scroll();
		setTimeout(scroll, 0); // overrides any previous scroll state made by the browser
	}

	function afterRender() {
		// after the view has been freshly rendered and sized
		if (!preventScrollReset) {
			resetScroll();
		}
		setTimeline();
	}

	/* Slot/Day clicking and binding
	-----------------------------------------------------------------------*/
	//Customized for seedcode to allow clicking on day numbers and more events
	function moreEventsBind(moreEvents) {
		moreEvents.click(moreEventsClick);
	}

	function dayBind(cells) {
		cells.click(slotClick).mousedown(daySelectionMousedown);
		cells.dblclick(slotDblClick);
		cells.addTouch(true);
	}

	function slotBind(cells) {
		cells.click(slotClick).mousedown(slotSelectionMousedown);
		cells.dblclick(slotDblClick);
		cells.addTouch(true);
	}

	//Customized for seedcode
	function moreEventsClick(ev) {
		if (!opt('selectable')) {
			// if selectable, SelectionManager will worry about dayClick
			var col = Math.min(
				colCnt * resourceDays - 1,
				Math.floor(
					(ev.pageX - dayTable.offset().left - axisWidth) / colWidth
				)
			);
			var date = cellToDate(0, Math.floor(col / colCnt));
			var match = this.parentNode.className.match(/fc-slot(\d+)/); // TODO: maybe use data
			if (match) {
				var slotIndex = parseInt(match[1]);
				date.add(minTime + slotIndex * slotDuration);
				date = calendar.rezoneDate(date);
				trigger('dayNumberClick', dayBodyCells[col], date, ev);
			} else {
				trigger('dayNumberClick', dayBodyCells[col], date, ev);
			}
		}
	}

	function slotClick(ev) {
		if (!opt('selectable')) {
			// if selectable, SelectionManager will worry about dayClick
			var col = Math.min(
				colCnt * resourceDays - 1,
				Math.floor(
					(ev.pageX - dayTable.offset().left - axisWidth) / colWidth
				)
			);
			var resource = resources[col % colCnt];
			var date = cellToDate(0, Math.floor(col / colCnt));
			var match = this.parentNode.className.match(/fc-slot(\d+)/); // TODO: maybe use data
			//add resource to the event so we can pass it through to the callback
			ev.resource = resource;

			if (match) {
				var slotIndex = parseInt(match[1]);
				// date.add(minTime + slotIndex * slotDuration); //Replaced this with the line below - Fixes DST issues
				date.time(moment.duration(minTime + slotIndex * slotDuration));
				date = calendar.rezoneDate(date);
				trigger('dayClick', dayBodyCells[col], date, ev);
			} else {
				trigger('dayClick', dayBodyCells[col], date, ev);
			}
		}
	}

	function slotDblClick(ev) {
		if (!opt('selectable')) {
			// if selectable, SelectionManager will worry about dayClick
			var col = Math.min(
				colCnt * resourceDays - 1,
				Math.floor(
					(ev.pageX - dayTable.offset().left - axisWidth) / colWidth
				)
			);
			var resource = resources[col % colCnt];
			var date = cellToDate(0, Math.floor(col / colCnt));
			var match = this.parentNode.className.match(/fc-slot(\d+)/); // TODO: maybe use data
			//add resource to the event so we can pass it through to the callback
			ev.resource = resource;

			if (match) {
				var slotIndex = parseInt(match[1]);
				// date.add(minTime + slotIndex * slotDuration); //Replaced this with the line below - Fixes DST issues
				date.time(moment.duration(minTime + slotIndex * slotDuration));
				date = calendar.rezoneDate(date);
				trigger('dayDblClick', dayBodyCells[col], date, ev);
			} else {
				trigger('dayDblClick', dayBodyCells[col], date, ev);
			}
		}
	}

	/* Semi-transparent Overlay Helpers
	-----------------------------------------------------*/
	// TODO: should be consolidated with BasicView's methods

	function renderDayOverlay(
		overlayStart,
		overlayEnd,
		resource,
		refreshCoordinateGrid
	) {
		// overlayEnd is exclusive
		if (refreshCoordinateGrid) {
			coordinateGrid.build();
		}

		var segments = resourceRangeToSegments(
			overlayStart,
			overlayEnd,
			true,
			resource
		);
		for (var i = 0; i < segments.length; i++) {
			var segment = segments[i];
			dayBind(
				renderCellOverlay(
					segment.row,
					segment.leftCol,
					segment.row,
					segment.rightCol
				)
			);
		}
	}

	function renderCellOverlay(row0, col0, row1, col1) {
		// only for all-day?
		var rect = coordinateGrid.rect(row0, col0, row1, col1, slotLayer);
		return renderOverlay(rect, slotLayer);
	}

	function renderSlotOverlay(overlayStart, overlayEnd, col) {
		// normalize, because dayStart/dayEnd have stripped time+zone
		overlayStart = overlayStart.clone().stripZone();
		overlayEnd = overlayEnd.clone().stripZone();

		for (var i = 0; i < colCnt; i++) {
			// loop through the day columns

			var dayStart = cellToDate(0, i);
			var dayEnd = dayStart.clone().add('days', 1);

			var stretchStart =
				dayStart < overlayStart ? overlayStart : dayStart; // the max of the two
			var stretchEnd = dayEnd < overlayEnd ? dayEnd : overlayEnd; // the min of the two

			if (stretchStart < stretchEnd) {
				var rect = coordinateGrid.rect(0, col, 0, col, slotContainer); // only use it for horizontal coords
				var top = computeDateTop(stretchStart, dayStart);
				var bottom = computeDateTop(stretchEnd, dayStart);

				rect.top = top;
				rect.height = bottom - top;
				slotBind(renderOverlay(rect, slotContainer));
			}
		}
	}

	/* Coordinate Utilities
	-----------------------------------------------------------------------------*/

	coordinateGrid = new CoordinateGrid(function (rows, cols) {
		var e, n, p;
		dayHeadCells.each(function (i, _e) {
			e = $(_e);
			n = e.offset().left;
			if (i) {
				p[1] = n;
			}
			p = [n];
			cols[i] = p;
		});
		p[1] = n + e.outerWidth();
		if (opt('allDaySlot')) {
			e = allDayRow;
			n = e.offset().top;
			rows[0] = [n, n + e.outerHeight()];
		}
		var slotTableTop = slotContainer.offset().top;
		var slotScrollerTop = slotScroller.offset().top;
		var slotScrollerBottom = slotScrollerTop + slotScroller.outerHeight();
		function constrain(n) {
			return Math.max(slotScrollerTop, Math.min(slotScrollerBottom, n));
		}
		for (var i = 0; i < slotCnt * snapRatio; i++) {
			// adapt slot count to increased/decreased selection slot count
			rows.push([
				constrain(slotTableTop + snapHeight * i),
				constrain(slotTableTop + snapHeight * (i + 1)),
			]);
		}
	});

	hoverListener = new HoverListener(coordinateGrid);

	colPositions = new HorizontalPositionCache(function (col) {
		return {bodyPosition: bodyPosition, cell: dayBodyCellInners.eq(col)};
	});

	colContentPositions = new HorizontalPositionCache(function (col) {
		return {
			bodyPosition: bodyPosition,
			cell: dayBodyCellContentInners.eq(col),
		};
	});

	function colLeft(col) {
		return colPositions.left(col);
	}

	function colContentLeft(col) {
		return colContentPositions.left(col);
	}

	function colRight(col) {
		return colPositions.right(col);
	}

	function colContentRight(col) {
		return colContentPositions.right(col);
	}

	// NOTE: the row index of these "cells" doesn't correspond to the slot index, but rather the "snap" index

	function getIsCellAllDay(cell) {
		// TODO: remove because mom.hasTime() from realCellToDate() is better
		return opt('allDaySlot') && !cell.row;
	}

	function realCellToDate(cell) {
		// ugh "real" ... but blame it on our abuse of the "cell" system
		var date = cellToDate(0, cell.col);
		var snapIndex = cell.row;

		if (opt('allDaySlot')) {
			snapIndex--;
		}

		if (snapIndex >= 0) {
			date.time(moment.duration(minTime + snapIndex * snapDuration));
			date = calendar.rezoneDate(date);
		}

		return date;
	}

	function computeDateTop(date, startOfDayDate) {
		return computeTimeTop(
			moment.duration(
				// date.clone().stripZone() - startOfDayDate.clone().stripTime() //This wasn't working properly on DST days. We need to change to below to fix DST issues.
				calendar.unzoneDate(date) -
					calendar.unzoneDate(startOfDayDate).stripTime()
			)
		);
	}

	function setTimeline() {
		var now = fc.createTimezoneTime(moment());
		var timeArr = now.format('HH:mm:ss').split(':');
		var timeInMilliseconds = timeArr[0] * 3600000 + timeArr[1] * 60000;
		var standardTime = now.format(opt('timeFormat'));
		var styleTop;
		var millisecondsOff;
		var timelineTimeoutID;
		var timelineContainer = $('#timelineContainer');

		if (now.isBefore(t.start) || now.isAfter(t.end)) {
			timelineContainer.css('display', 'none');
		} else {
			// Style timeline top height
			styleTop = computeTimeTop(moment.duration(timeInMilliseconds))
				.toFixed(2)
				.toString();
			timelineContainer.css('top', styleTop + 'px');

			// Change text of timeline to current time
			$('#timelineTime').text(standardTime);
			timelineContainer.css('display', 'block');
		}

		// if millisecondsOff greater than 2000 milliseconds setTimeout at most 2.1 second behind real time
		// else setTimeout at most 2.1 seconds behind real time
		millisecondsOff = (60 - timeArr[2]) * 1000;
		if (millisecondsOff >= 2000) {
			t.calendar.timelineTimeoutID = window.setTimeout(
				setTimeline,
				millisecondsOff + 2100
			);
		} else {
			t.calendar.timelineTimeoutID = window.setTimeout(
				setTimeline,
				62100
			);
		}
	}

	function computeTimeTop(time) {
		// time is a duration

		if (time < minTime) {
			return 0;
		}
		if (time >= maxTime) {
			return slotTable.height();
		}

		var slots = (time - minTime) / slotDuration;
		var slotIndex = Math.floor(slots);
		var slotPartial = slots - slotIndex;
		var slotTop = slotTopCache[slotIndex];

		// find the position of the corresponding <tr>
		// need to use this tecnhique because not all rows are rendered at same height sometimes.
		if (slotTop === undefined) {
			slotTop = slotTopCache[slotIndex] = slotTable
				.find('tr')
				.eq(slotIndex)
				.find('td div')[0].offsetTop;
			// .eq() is faster than ":eq()" selector
			// [0].offsetTop is faster than .position().top (do we really need this optimization?)
			// a better optimization would be to cache all these divs
		}

		var top =
			slotTop -
			1 + // because first row doesn't have a top border
			slotPartial * slotHeight; // part-way through the row

		top = Math.max(top, 0);

		return top;
	}

	/* Selection
	---------------------------------------------------------------------------------*/

	function defaultSelectionEnd(start) {
		if (start.hasTime()) {
			// return start.clone().add(slotDuration); Changed to below for DST fix.
			return calendar.rezoneDate(
				calendar.unzoneDate(start).add(slotDuration)
			);
		} else {
			return start.clone().add('days', 1);
		}
	}

	function renderSelection(start, end) {
		if (start.hasTime() || end.hasTime()) {
			renderSlotSelection(start, end);
		} else if (opt('allDaySlot')) {
			renderDayOverlay(start, end, true); // true for refreshing coordinate grid
		}
	}

	function renderSlotSelection(startDate, endDate) {
		var helperOption = opt('selectHelper');
		coordinateGrid.build();
		if (helperOption) {
			var col = dateToCell(startDate).col;
			if (col >= 0 && col < colCnt) {
				// only works when times are on same day
				var rect = coordinateGrid.rect(0, col, 0, col, slotContainer); // only for horizontal coords
				var top = computeDateTop(startDate, startDate);
				var bottom = computeDateTop(endDate, startDate);
				if (bottom > top) {
					// protect against selections that are entirely before or after visible range
					rect.top = top;
					rect.height = bottom - top;
					rect.left += 2;
					rect.width -= 5;
					if ($.isFunction(helperOption)) {
						var helperRes = helperOption(startDate, endDate);
						if (helperRes) {
							rect.position = 'absolute';
							selectionHelper = $(helperRes)
								.css(rect)
								.appendTo(slotContainer);
						}
					} else {
						rect.isStart = true; // conside rect a "seg" now
						rect.isEnd = true; //
						selectionHelper = $(
							slotSegHtml(
								{
									title: '',
									start: startDate,
									end: endDate,
									className: ['fc-select-helper'],
									editable: false,
								},
								rect
							)
						);
						selectionHelper.css('opacity', opt('dragOpacity'));
					}
					if (selectionHelper) {
						slotBind(selectionHelper);
						slotContainer.append(selectionHelper);
						setOuterWidth(selectionHelper, rect.width, true); // needs to be after appended
						setOuterHeight(selectionHelper, rect.height, true);
					}
				}
			}
		} else {
			renderSlotOverlay(startDate, endDate);
		}
	}

	function clearSelection() {
		clearOverlays();
		if (selectionHelper) {
			selectionHelper.remove();
			selectionHelper = null;
		}
	}

	function slotSelectionMousedown(ev) {
		if (ev.which == 1 && opt('selectable')) {
			// ev.which==1 means left mouse button
			unselect(ev);
			var dates;
			hoverListener.start(function (cell, origCell) {
				clearSelection();
				if (
					cell &&
					cell.col == origCell.col &&
					!getIsCellAllDay(cell)
				) {
					var d1 = realCellToDate(origCell);
					var d2 = realCellToDate(cell);
					dates = [
						d1,
						calendar.rezoneDate(
							calendar.unzoneDate(d1).add(snapDuration)
						), // calculate minutes depending on selection slot minutes
						d2,
						calendar.rezoneDate(
							calendar.unzoneDate(d2).add(snapDuration)
						),
					].sort(dateCompare);
					renderSlotSelection(dates[0], dates[3]);
				} else {
					dates = null;
				}
			}, ev);
			$(document).one('mouseup', function (ev) {
				hoverListener.stop();
				if (dates) {
					if (+dates[0] == +dates[1]) {
						reportDayClick(dates[0], ev);
					}
					reportSelection(dates[0], dates[3], ev);
				}
			});
		}
	}

	function reportDayClick(date, ev) {
		trigger('dayClick', dayBodyCells[dateToCell(date).col], date, ev);
	}

	/* External Dragging
	--------------------------------------------------------------------------------*/

	function dragStart(_dragElement, ev, ui) {
		hoverListener.start(function (cell) {
			const draggedEvent = ui?.helper?.data;

			clearOverlays();
			if (cell) {
				const newStart = realCellToDate({
					row: cell.row,
					col: Math.floor(cell.col / colCnt),
				});
				const dates = createExternalDragTimeDates(
					newStart,
					draggedEvent
				);
				const resource = t.getResources(false)[cell.col % colCnt];
				if (dates.hasTime) {
					renderSlotOverlay(dates.start, dates.end, cell.col);
				} else {
					renderDayOverlay(dates.start, dates.end, [resource.name]);
				}
			}
		}, ev);
	}

	function dragStop(_dragElement, ev, ui) {
		const cell = hoverListener.stop();
		const draggedEvent = ui?.helper?.data;
		const isClone = manageClone(ev, ui, draggedEvent);

		clearOverlays();

		if (cell) {
			const resource = t.getResources(false)[cell.col % colCnt];
			const newStart = realCellToDate({
				row: cell.row,
				col: Math.floor(cell.col / colCnt),
			});
			const dates = createExternalDragTimeDates(newStart, draggedEvent);
			trigger(
				'drop',
				_dragElement,
				dates.start,
				dates.end,
				ev,
				ui,
				{
					value: resource,
					field: 'resource',
				},
				isClone
			);
		}
	}
}
